export var SearchList = [
  { labe: '库位名称', code: 'entrPosiName', type: 'input' },
  {
    labe: '库位类型',
    code: 'entrPosiType',
    type: 'select',
    option: [
      { label: '全部', value: 0 },
      { label: '外协库', value: 1 },
      { label: '返修库', value: 2 },
      { label: '工厂库', value: 3 },
      { label: 'vmi库', value: 4 }
    ]
  }
]
export var SearchData = {
  entrPosiName: '',
  entrPosiType: ''
}
export var tableField = [
  { label: '序号', type: 'function', width: '50px', handle: (index, data) => { return index + 1 } },
  { label: '库位名称', code: 'entrPosiName', type: 'input', width: '' },
  {
    label: '库位类型',
    code: 'entrPosiType',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.entrPosiType === 1 ? '外协库' : data.entrPosiType === 2 ? '返修库' : data.entrPosiType === 3 ? '工厂库' : data.entrPosiType === 4 ? 'vmi库' : '其他地址'
    }
  },
  { label: '库位地址', code: 'entrPosiAddr', type: 'input', width: '' },
  { label: '联系人', code: 'entrPosiHead', type: 'input', width: '' },
  { label: '联系方式', code: 'entrPosiHeadPhone', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [{
      actionLabel: '编辑',
      id: '10'
    }, {
      actionLabel: '删除',
      id: '11'
    }]
  }
]

export var addrTableField = [
  { label: '地址名称', code: 'addressName', type: 'input', width: '' },
  {
    label: '地址属性',
    code: 'addressProperty',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.addressProperty === '1' ? '返修地址' : data.addressProperty === '2' ? '仓库地址' : '其他地址'
    }
  },
  { label: '地址', type: 'function', width: '', handle: (index, data) => { return data.province + data.city + data.area + data.detailedAddress } }
]

export var ContTableField = [
  { label: '姓名', code: 'name', type: 'input', width: '' },
  { label: '联系方式', code: 'phone', type: 'input', width: '' },
  { label: '部门', code: 'deptCode', type: 'input', width: '' },
  { label: '备注', code: 'typeName', type: 'input', width: '' }
]
