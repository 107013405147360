<template>
    <div class="list">
        <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
        <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
        <global-table
        :tableField="tableField"
        :tableData="tableData"
        :paginationData="paginationData"
        @pageChange="pageChange"
        @tableAction="tableAction"
        ></global-table>
        <dialog-box ref="dialogbox" dialogWidth="50%" :dialogShow="dialogShow" :formData = "showData" componentName="AddLocation"
                @handleClose='handleClose' :title="title"
                :buttonData="buttonData" @action="action" ></dialog-box>
    </div>
</template>

<script>
import { request, requestForm } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField } from './js/setting.js'
/* eslint-disable eqeqeq */
export default {
  name: 'LocationManagement',
  components: Component.components,
  data: function () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      menudata: this.$store.state.ViewMenuData.menuData,
      tableField: tableField,
      tableData: [],
      paginationData: { pageSize: pageSize, total: 1 },
      searchdata: {},
      dialogShow: false,
      title: '添加库位',
      showData: {},
      pageSize: pageSize,
      buttonData: [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  },
  created: function () {
    request('/api/livraison/entrepot/getEntrepotInfoList', 'post', { pageNum: 1, pageSize: this.pageSize }).then((Response) => {
      if (Response.code == '200') {
        this.tableData = Response.data.records
        this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
      }
    })
  },
  methods: {
    tableAction: function (index, data, act) {
      if (act.id === '10') {
        this.title = '编辑库位'
        this.dialogShow = true
        this.showData = data
        this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
      }
      if (act.id === '11') {
        this.$confirm('确认删除“' + data.entrPosiName + '”？', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          requestForm('/api/livraison/entrepot/delateEntrepotInfo', 'post', { entrepotId: data.id }).then((Response) => {
            if (Response.code == '200') {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              request('/api/livraison/entrepot/getEntrepotInfoList', 'post', { pageNum: 1, pageSize: this.pageSize }).then((Response) => {
                if (Response.code == '200') {
                  this.tableData = Response.data.records
                  this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
                }
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      }
    },
    action: function () {
      let ps = false
      this.$refs.dialogbox.$refs.content.$refs.form.validate((vs) => {
        ps = !vs
      })
      if (ps) {
        return false
      }
      let requri = ''
      if (this.title == '新增库位') {
        requri = '/api/livraison/entrepot/saveEntrepotInfo'
      } else {
        requri = '/api/livraison/entrepot/updateEntrepotInfo'
      }
      request(requri, 'post', this.$refs.dialogbox.$refs.content.form).then((Response) => {
        if (Response.code == '201') {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.pageChange(1)
        }
        if (Response.code == '200') {
          this.$message({
            message: '新增成功',
            type: 'success'
          })
          this.pageChange(1)
        }
      })
      this.dialogShow = false
    },
    handleClose: function () {
      this.dialogShow = false
    },
    buttonClick: function () {
      this.title = '新增库位'
      this.dialogShow = true
      this.showData = {}
      this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
    },
    pageChange: function (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      request('/api/livraison/entrepot/getEntrepotInfoList', 'post', this.searchdata).then((Response) => {
        if (Response.code == '200') {
          this.tableData = Response.data.records
          if (this.paginationData.total !== Response.data.total) {
            this.paginationData.total = Response.data.total
          }
        }
      })
    },
    search: function (data) {
      data.pageNum = 1
      data.pageSize = this.pageSize
      this.searchdata = data
      request('/api/livraison/entrepot/getEntrepotInfoList', 'post', data).then((Response) => {
        if (Response.code == '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
